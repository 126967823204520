import React from "react"
import { Testimonials } from "../components/archive/index/testimonial"
import Jumbotron from "../components/archive/jumbotron"
import Layout from "../components/archive/layout"
import Button from "../components/archive/lib/button"
import Grid from "../components/archive/lib/grid"
import Section from "../components/archive/section"
import SEO from "../components/archive/seo"
import theme from "../components/archive/theme"
import headerLogo from "../images/logo_header-light.svg"
import Image from "gatsby-image"
import { graphql } from "gatsby"
import styled from "styled-components"

export const Paragraph = styled.p`
  font-weight: 300;
  font-size: 1.7rem;
  line-height: 2.4rem;
  margin: 0;
  @media only screen and (min-width: ${props => props.theme.tablet}px) {
    font-size: 2rem;
    line-height: 3.2rem;
    max-width: 80%;
  }
  & + & {
    margin-top: 2rem;
  }
`

const clients = [
  { alt: "Dallas Mavericks", filename: "mavs" },
  { alt: "World Table Tennis", filename: "wtt" },
  { alt: "American Airlines Center", filename: "aac" },
  { alt: "Indy Elevens", filename: "indy" },
  { alt: "Inter Miami CF", filename: "imcf" },
]

const Tixsee = ({ data }) => {
  return (
    <Layout initialHeaderLight={true} tixsee>
      <SEO title="Tixsee" />
      <Jumbotron
        dark={false}
        css={{ textAlign: "center", paddingTop: theme.verticalSpacing(4) }}
      >
        <Jumbotron.Title
          css={{
            maxWidth: 1200,
            position: "relative",
            fontWeight: 800,
            color: theme.oilslick90,
          }}
        >
          Delivering{" "}
          <span css={{ color: theme.highlightBlue }}>
            Exceptional Experiences
          </span>{" "}
          that help Businesses Grow
        </Jumbotron.Title>
        <Paragraph
          css={{
            margin: "auto",
            marginTop: theme.verticalSpacing(3),
            color: theme.oilslick90,
          }}
        >
          A subsidiary of
        </Paragraph>
        <img
          css={{
            display: "block",
            margin: "auto",
            marginTop: theme.verticalSpacing(1),
          }}
          src={headerLogo}
          alt="pumpjack dataworks"
        />
      </Jumbotron>
      <Jumbotron>
        <Jumbotron.Description>
          We are a fan experience technology company working at the intersection
          of the team, venue, and fan. Our unified data platform uncovers new
          stories about our clients’ fans that open up new digital revenue for
          them and their partners.
        </Jumbotron.Description>
      </Jumbotron>
      <Section>
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <Jumbotron.Title>Clients who loved our work</Jumbotron.Title>
              <Jumbotron.Description>
                Helping some truly outstanding teams, leagues and businesses
                over the years to build stronger relationship with their users.
                Check out a few of our clients below.
              </Jumbotron.Description>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row
            css={{
              justifyContent: "center",
              marginTop: theme.verticalSpacing(2),
              paddingBottom: theme.verticalSpacing(4),
            }}
          >
            {clients.map(item => (
              <Grid.Col
                sm={18 / clients.length}
                lg={12 / clients.length}
                mdBottom={2}
              >
                <Image
                  style={{ filter: "invert(100%)" }}
                  fluid={data[item.filename].childImageSharp.fluid}
                  alt={item.alt}
                />
              </Grid.Col>
            ))}
          </Grid.Row>
          <Testimonials />
        </Grid>
      </Section>
      <Jumbotron dark={false}>
        <Jumbotron.Title>We're Hiring</Jumbotron.Title>
        <Jumbotron.Description>
          Join our team to help shape the future of entertainment for passionate
          fan communities.
        </Jumbotron.Description>
        <Button
          as="a"
          css={{
            display: "inline-block",
            margin: "4rem auto",
            textDecoration: "none",
          }}
          href="/careers"
        >
          View Open Positions
        </Button>
      </Jumbotron>
    </Layout>
  )
}

export default Tixsee

export const query = graphql`
  query {
    mavs: file(relativePath: { eq: "clients/mavs.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    aac: file(relativePath: { eq: "clients/aac.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imcf: file(relativePath: { eq: "clients/imcf.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    indy: file(relativePath: { eq: "clients/indy.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    wtt: file(relativePath: { eq: "clients/wtt.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
