import React from "react"
import Grid from "../lib/grid"
import { css } from "styled-components"
import interMiamiLogo from "../../../images/inter-miami-logo.svg"
import dallasMavsLogo from "../../../images/dallas-mavs-logo.svg"

const Testimonial = ({ themeColor, name, teamName, title, img, children }) => (
  <div
    css={css`
      width: 100%;
      height: 100%;
      display: flex;
      overflow: hidden;
    `}
  >
    <div
      css={css`
        padding: ${props =>
          `${props.theme.verticalSpacing(1.5)} ${props.theme.horizontalSpacing(
            1
          )}`};
        background-color: ${themeColor};
        border-radius: 8px;
        @media only screen and (max-width: ${props =>
            props.theme.tablet - 1}px) {
          display: none;
        }
      `}
    >
      <img
        css={css`
          width: 80px;
        `}
        src={img}
        alt={`${name} logo`}
      />
    </div>
    <div
      css={css`
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        @media only screen and (min-width: ${props => props.theme.tablet}px) {
          padding: ${props => props.theme.verticalSpacing(2)} 20px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-bottom: ${props => props.theme.verticalSpacing(1)};
        `}
      >
        <img
          css={css`
            width: 40px;
            margin-right: 10px;
            @media only screen and (min-width: ${props =>
                props.theme.tablet}px) {
              display: none;
            }
          `}
          src={img}
          alt={`${name} logo`}
        />
        <h3
          css={css`
            margin: 0;
            font-size: 1.4rem;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 2px;
            line-height: 1.6rem;
            color: ${themeColor};
          `}
        >
          {teamName}
        </h3>
      </div>
      <p
        css={css`
          font-size: 2rem;
          line-height: 2.4rem;
          margin: 0;
          flex-grow: 1;
          @media only screen and (min-width: ${props => props.theme.tablet}px) {
            font-size: 2.4rem;
            line-height: 3.2rem;
          }
        `}
      >
        {children}
      </p>
      <div
        css={css`
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 2.4rem;
          margin-top: ${props => props.theme.verticalSpacing(1)};
          @media only screen and (min-width: ${props => props.theme.tablet}px) {
            margin-top: ${props => props.theme.verticalSpacing(2)};
          }
        `}
      >
        {name}
      </div>
      <div
        css={css`
          font-size: 1.6rem;
          line-height: 2.4rem;
        `}
      >
        {title}
      </div>
    </div>
  </div>
)

export default Testimonial

export function Testimonials() {
  return (
    <Grid.Row
      css={css`
        margin-top: ${props => props.theme.verticalSpacing(6)};
      `}
    >
      <Grid.Col sm={12} lg={6} mdBottom={2}>
        <Testimonial
          themeColor="#0d66af"
          name="David Herr"
          title="Chief Technology Officer"
          teamName="Dallas Mavericks"
          img={dallasMavsLogo}
        >
          “Pumpjack is evolving how our organization communicates with our data.
          Their technology has made it possible for us to energize mobile
          adoption, create new revenue, and most important—provide our fans with
          an exceptional experience.”
        </Testimonial>
      </Grid.Col>
      <Grid.Col sm={12} lg={6}>
        <Testimonial
          themeColor="#231f20"
          name="Jean Manuel Jimenez"
          title="VP Digital, Content and Broadcast"
          teamName="Inter Miami CF"
          img={interMiamiLogo}
        >
          “Pumpjack is our key partner executing our digital strategy—building a
          tremendous fan experience, with a long term focus on building a data
          foundation to maximize revenue.”
        </Testimonial>
      </Grid.Col>
    </Grid.Row>
  )
}
